import React from 'react';
import { BlockFooter, Maybe } from '@allergan-data-labs/contentful-graphql';
import { IBlockFooterFields } from '@allergan-data-labs/contentful-rest';
import { RichText } from '../richText/richText';

export interface ConsumerFooterProps {
  copyright: React.ReactNode;
  PRT?: string;
  hideGlobalISILink?: boolean;
  hideAbout?: boolean;
}

const isGqlType = (
  footer?: Maybe<BlockFooter> | IBlockFooterFields
): footer is BlockFooter => {
  return (footer as Maybe<BlockFooter>)?.__typename === 'BlockFooter';
};

// PRT prop must be passed in to component seperately, depending on whether or not PRT is part of copyright
export const cmsConsumerFooterToProps = (
  contentfulFooter?: Maybe<BlockFooter> | IBlockFooterFields
): Omit<ConsumerFooterProps, 'PRT'> => {
  return {
    hideGlobalISILink: contentfulFooter?.showGlobalIsi === false || false,
    hideAbout: contentfulFooter?.showAboutLinks === false || false,
    copyright: (
      <RichText
        richText={
          isGqlType(contentfulFooter)
            ? contentfulFooter?.footerCopy?.json
            : contentfulFooter?.footerCopy
        }
        links={
          isGqlType(contentfulFooter)
            ? contentfulFooter.footerCopy?.links?.entries?.hyperlink
            : undefined
        }
        paragraphPreset={{
          s: 'body-S-regular',
          m: 'body-S-regular',
          l: 'body-M-regular',
        }}
      />
    ),
  };
};
