import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const FacebookLogo: React.FunctionComponent<{
  pathFill?: string;
  className?: string;
}> = ({ pathFill, className }) => (
  <IconContainer className={className} pathFill={pathFill}>
    <SvgIcon width="22px" height="22px" viewBox="0 0 22 22">
      <path
        d="M12.4985904,19 L12.4985904,11.7021832 L14.9950055,11.7021832 L15.3695414,8.85725059 L12.4985904,8.85725059 L12.4985904,7.04117598 C12.4985904,6.21775956 12.7307563,5.65661117 13.9359585,5.65661117 L15.4705882,5.65599237 L15.4705882,3.11138398 C15.2051956,3.07755626 14.2941965,3 13.2338878,3 C11.019794,3 9.50398575,4.32557255 9.50398575,6.75941575 L9.50398575,8.85725059 L7,8.85725059 L7,11.7021832 L9.50398575,11.7021832 L9.50398575,19 L12.4985904,19 Z"
        id="path-1"
      ></path>
    </SvgIcon>
  </IconContainer>
);

export { FacebookLogo };
