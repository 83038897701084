import React, { useEffect, useContext, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import {
  NotificationContext,
  OnCloseCallbackParams,
} from '../notificationBar/notificationContext';
import { Link } from '../core/link';
import { ABBVIE_URLS } from '@allergan-data-labs/universal-component-library/src/constants/urls';

const cookieName = 'adl-privacy-banner';
const usePrivacyBanner = (
  onClose: () => void,
  onClickLink: () => void,
  isEnabled?: boolean
) => {
  const [cookies, setCookie] = useCookies([cookieName]);
  const { addNotification } = useContext(NotificationContext);
  const hasSeenNotificationBar = cookies[cookieName];
  const privacyBannerAcknowledged = useCallback(
    ({ isClearAll }: OnCloseCallbackParams) => {
      !isClearAll &&
        setCookie(cookieName, 'true', {
          maxAge: 2628000, // 1 month
          sameSite: true,
          path: '/',
        });
      onClose?.();
    },
    [setCookie, onClose]
  );

  useEffect(() => {
    if (isEnabled && !hasSeenNotificationBar) {
      addNotification({
        id: 'privacy-banner',
        text: (
          <>
            See our new privacy terms at{' '}
            <Link
              to={ABBVIE_URLS.privacyPolicy}
              external={!ABBVIE_URLS.privacyPolicy.startsWith('/')}
              style={{ textDecoration: 'underline' }}
              onClick={onClickLink}
            >
              {ABBVIE_URLS.privacyPolicy}
            </Link>
          </>
        ),
        variant: 'general',
        onCloseCallback: privacyBannerAcknowledged,
      });
    }
  }, [
    isEnabled,
    addNotification,
    hasSeenNotificationBar,
    onClickLink,
    privacyBannerAcknowledged,
  ]);
};

export { usePrivacyBanner };
