import gql from 'graphql-tag';

import {
  ConsumerProfile,
  DateTime,
} from '@allergan-data-labs/alle-graphql-types';

export const QUERY_PROFILE = gql`
  query ConsumerProfileQuery {
    viewer {
      id
      profile {
        __typename
        ... on ConsumerProfile {
          id
          firstName
          lastName
          emailAddress
          phoneNumber
          loyalty {
            id
            tier
          }
          privacyTermsAcceptedAt {
            format(layout: "MM.DD.YY")
          }
          hipaaAcceptedAt {
            format(layout: "MM.DD.YY")
          }
        }
      }
    }
  }
`;

export const CONSUMER_TIER = {
  AList: 'A_LIST',
  Member: 'MEMBER',
} as const;

export type ConsumerTier = (typeof CONSUMER_TIER)[keyof typeof CONSUMER_TIER];

export interface ConsumerViewerProfile {
  __typename: string;
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  hipaaAcceptedAt: DateTime | null;
  privacyTermsAcceptedAt: DateTime | null;
  loyalty: {
    tier: ConsumerTier;
  } | null;
}

export interface ConsumerProfileQuery {
  viewer: {
    id: string;
    profile: ConsumerViewerProfile;
  } | null;
}

export interface ConsumerProfileQueryDeprecated {
  viewer: {
    id: string;
    profile: ConsumerProfile;
  };
}
