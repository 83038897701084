import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const TwitterLogo: React.FunctionComponent = () => (
  <IconContainer>
    <SvgIcon width="22px" height="22px" viewBox="0 0 22 22">
      <path
        d="M19.9999722,5.63380556 C19.3742778,5.91105556 18.7029167,6.09908333 17.9975278,6.18302778 C18.7177778,5.75172222 19.2691111,5.06761111 19.5304167,4.25494444 C18.8548056,4.65436111 18.1090556,4.94438889 17.3144444,5.10161111 C16.6781389,4.42280556 15.7730556,4 14.7691667,4 C12.8432222,4 11.2816389,5.56158333 11.2816389,7.48647222 C11.2816389,7.75947222 11.3124444,8.02611111 11.3719444,8.28108333 C8.474,8.13552778 5.90430556,6.74711111 4.18444444,4.63736111 C3.88380556,5.15152778 3.71277778,5.75063889 3.71277778,6.39016667 C3.71277778,7.60013889 4.32891667,8.66775 5.26375,9.29236111 C4.69222222,9.27325 4.15469444,9.11602778 3.68411111,8.85469444 L3.68411111,8.89825 C3.68411111,10.5873056 4.88663889,11.9969722 6.48113889,12.3178056 C6.189,12.3964167 5.88094444,12.4399722 5.56225,12.4399722 C5.33705556,12.4399722 5.11927778,12.4176667 4.90575,12.3751667 C5.34977778,13.7614722 6.63730556,14.7695833 8.16277778,14.7972222 C6.96980556,15.7320556 5.46558333,16.2876389 3.83177778,16.2876389 C3.55027778,16.2876389 3.273,16.2706389 3,16.2398333 C4.54352778,17.2309722 6.376,17.8088611 8.3455,17.8088611 C14.7607222,17.8088611 18.2673889,12.4952222 18.2673889,7.88697222 L18.2556944,7.4355 C18.9408611,6.94680556 19.5336111,6.33280556 19.9999722,5.63380556 Z"
        id="path-1"
      ></path>
    </SvgIcon>
  </IconContainer>
);

export { TwitterLogo };
