import { useDecision } from '@optimizely/react-sdk';
import { hasAlleFeatureOverride } from '../optimizely/alleFeatureFlag';

const useFeatureFlag = (featureKey: string) => {
  // Set default existing feature flag if feature key is empty string to avoid Optimizely Error.
  // https://app.optimizely.com/v2/projects/17264302525/features/22799124309/settings
  if (featureKey.length === 0) {
    featureKey = 'noop';
  }
  const [decision, isReady, didTimeout] = useDecision(featureKey, {
    autoUpdate: true,
  });
  return {
    enabled: hasAlleFeatureOverride(featureKey) || decision.enabled,
    variables: decision.variables,
    isReady,
    didTimeout,
  };
};

export { useFeatureFlag };
