import React from 'react';
import queryString from 'query-string';
import { UTM_TRACKING_KEY } from '../constants/localStorage';
import deepEqual from 'deep-equal';

declare global {
  interface Window {
    ga: any;
  }
}

// Example URL = https://alle.com/upgrade?utm_source=ADL&utm_medium=Display&utm_campaign=Allē-BDMigrateCampaign-2020

type Milliseconds = number;
type QueryParam = string;

type UTMTrackingParams = Partial<{
  utm_source: QueryParam;
  utm_medium: QueryParam;
  utm_campaign: QueryParam;
  utm_term: QueryParam;
  utm_content: QueryParam;
  expiresAt: Milliseconds;
}>;

const saveToLocalStorage = (
  utmPayload: UTMTrackingParams,
  now: Milliseconds
) => {
  if (Object.keys(utmPayload).some((x) => x)) {
    const sevenDays = 1000 * 60 * 60 * 24 * 7;
    utmPayload.expiresAt = now + sevenDays;
    localStorage.setItem(UTM_TRACKING_KEY, JSON.stringify(utmPayload));
  }
};

const convertToString = (utmValue: string | string[]) => {
  if (Array.isArray(utmValue)) {
    return utmValue.join(',');
  }

  return utmValue;
};

const useUTMPageTracking = () => {
  const saveUtmTrackingParams = React.useCallback((search: string) => {
    // no data to set, exit early
    if (!search || search.length === 0) {
      return;
    }

    const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } =
      queryString.parse(search);

    const nextUtmPayload: UTMTrackingParams = {
      ...(utm_source ? { utm_source: convertToString(utm_source) } : {}),
      ...(utm_medium ? { utm_medium: convertToString(utm_medium) } : {}),
      ...(utm_campaign ? { utm_campaign: convertToString(utm_campaign) } : {}),
      ...(utm_term ? { utm_term: convertToString(utm_term) } : {}),
      ...(utm_content ? { utm_content: convertToString(utm_content) } : {}),
    };

    const {
      expiresAt: currentExpiresAt,
      ...currentUtmPayload
    }: UTMTrackingParams = JSON.parse(
      localStorage.getItem(UTM_TRACKING_KEY) ?? '{}'
    );

    const now = Date.now();
    if (currentExpiresAt === undefined || currentExpiresAt < now) {
      saveToLocalStorage(nextUtmPayload, now);
    }

    // nothing changes, exit early
    if (deepEqual(currentUtmPayload, nextUtmPayload)) {
      return;
    }

    // save the new data
    saveToLocalStorage(nextUtmPayload, now);
  }, []);

  const getUtmTrackingParams = React.useCallback(() => {
    const rawUTMData = localStorage.getItem(UTM_TRACKING_KEY);
    if (rawUTMData) {
      const utmPayload: UTMTrackingParams = JSON.parse(rawUTMData);

      if (utmPayload.expiresAt && utmPayload.expiresAt < Date.now()) {
        localStorage.removeItem(UTM_TRACKING_KEY);
        return {};
      }

      return utmPayload;
    }

    return {};
  }, []);

  return {
    saveUtmTrackingParams,
    getUtmTrackingParams,
  };
};

const useGoogleAnalytics = () => {
  const getGAClientId = React.useCallback(() => {
    if (window.ga !== undefined) {
      // `getAll()` is only available on the `ga` object once `analytics.js` has loaded
      // `getGAClientId` is initiated by user actions which occur after page load
      // If `getAll()` is not a valid function then `analytics.js` has failed to load: no tracker and `clientId` will be available
      if (window.ga.getAll === undefined) {
        console.error(
          'analytics.js did not load and `window.ga.getAll()` is not available'
        );
        return null;
      }

      const trackers = window.ga.getAll();
      if (trackers.length === 0) {
        console.error('No trackers detected');
      }
      if (trackers.length > 0) {
        // The same `clientId` exists on all trackers
        return trackers[0].get('clientId');
      }
    }
    return null;
  }, []);

  return {
    getGAClientId,
  };
};

export { useUTMPageTracking, useGoogleAnalytics };
