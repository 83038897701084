enum Routes {
  'about' = '/about',
  'accountDashboard' = '/account/dashboard',
  'accountProviders' = '/account/providers',
  'accountWallet' = '/account/wallet',
  'appRequired' = '/app-required',
  'browseStates' = '/browse',
  'browseCities' = '/browse/geo/:state',
  'browseCity' = '/browse/geo/:state/:city',
  'castingcall' = '/castingcall',
  'discoverLocation' = '/discover/:location',
  'discoverProductOrTreatment' = '/discover/:location/:productOrTreatment',
  'referAFriend' = '/refer-a-friend',
  'referAFriendWithCode' = '/refer-a-friend/:referringUserCode',
  'article' = '/article/:slug',
  'articlePreview' = '/preview/article/:slug',
  'articlePreviewTabs' = '/preview/article/:slug/tabs',
  'authCallback' = '/implicit/callback',
  'beforeAndAfterPreview' = '/preview/before-and-after/:entryId',
  'botoxCosmeticCasting' = '/botoxcosmeticcasting',
  'botoxCosmeticDay' = '/botoxcosmeticday',
  'brandRegistration' = '/join/:brandName',
  'businessLandingPagePreview' = '/preview/business-lp/:slug',
  'contentfulLandingPage' = '/lp/:slug',
  'debugThrownErrors' = '/debug/thrown-errors',
  'debugSplitIntegration' = '/debug/split-integration',
  'debugDeviceLocation' = '/debug/device-location',
  'discover' = '/discover', // To be removed in favor of /articles on PD-4180
  'articles' = '/articles',
  'financingLandingPage' = '/lp/financing',
  'financingLandingPageRedirect' = '/financing',
  'financingApplication' = '/financing/application',
  'financingRequest' = '/financing/request/:financeRequestId',
  'financingOptions' = '/financing/:financeRequestId/options',
  'forgotPassword' = '/forgot-password',
  'getStarted' = '/get-started',
  'giftCardDetail' = '/gift-card/:cardId',
  'globalIsi' = '/global-isi',
  'help' = '/help',
  'home' = '/',
  'homeIndex' = '/index.html',
  'howItWorks' = '/how-alle-works',
  'juvedermDay' = '/juvedermday',
  'landingPagePreview' = '/preview/lp/:slug',
  'landingPagePreviewTabs' = '/preview/lp/:slug/tabs',
  'login' = '/login',
  'logout' = '/logout',
  'mothersDay' = '/mothersday',
  'natrelleOffer' = '/natrelleOffer',
  'optInAdvertisingOffer' = '/offer/:offerSlug/ad/:id',
  'optInAdvertisingOfferClaim' = '/offer/:offerSlug/ad/:id/claim',
  'optInGrowPromotion' = '/grow/:id/:variantId?',
  'optInGrowPromotionClaim' = '/grow/:id/claim',
  'optInOffer' = '/offer/:offerSlug',
  'optInOfferLandingPagePreview' = '/preview/offer/:offerSlug',
  'optInOfferLandingPagePreviewTabs' = '/preview/offer/:offerSlug/screens',
  'optInOfferClaim' = '/offer/:offerSlug/claim',
  'optOutConfirmation' = '/grow/opted-out-confirmation/:token',
  'offerActivation' = '/offer-activation/:token',
  'ourBrands' = '/our-brands',
  'patientOfferDetails' = '/patient/offer/:offerId',
  'paymentPlansLandingPage' = '/lp/payment-plans',
  'paymentPlansRedirect' = '/payment-plans',
  'printHIPAA' = '/legal/print-hipaa',
  'pointsAndEarning' = '/points-and-earning',
  'providerProfile' = '/search/:providerSlug',
  'providerSearch' = '/search',
  'providerSearchLandingPage' = '/provider-search/:slug',
  'providerSearchLandingPagePreview' = '/preview/provider-search/:slug',
  'register' = '/register',
  'registration' = '/registration/verify/:phoneNumber/:code',
  'registrationComplete' = '/registration/complete',
  'registrationPhoneNumber' = '/registration',
  'resetPassword' = '/reset-password/:token',
  'resorts' = '/resortsworld',
  'rewards' = '/rewards',
  'settingsEmail' = '/settings/communication/email',
  'settingsPrivacy' = '/settings/privacy',
  'settingsSms' = '/settings/communication/sms',
  'signup' = '/signup',
  'sweepsLandingPage' = '/sweeps/:slug',
  'termsAndConditions' = '/terms-and-conditions',
  'testMockHeaders' = '/test_mock_headers',
  'treatments' = '/account/treatments',
  'treatmentGuide' = '/treatment-guide/:guide',
  'upgrade' = '/upgrade',
  'userResetPassword' = '/user-reset-password/:token',
  'verifyEmail' = '/verify/email/:emailAddress/:token',
  'verifyPhoneChange' = '/v/pc/:phoneNumber/:token',
}

export { Routes };
