import React from 'react';
import { CmsLegalContent } from '@allergan-data-labs/alle-graphql-types';
import {
  ImportantSafetyInformationTemplateIsiHeaderTemplate,
  Maybe,
} from '@allergan-data-labs/contentful-graphql';

export interface MarkdownDialogToggleActions {
  type: 'toggle';
}

export interface MarkdownDialogOpenActions {
  type: 'openDialog';
}

export interface MarkdownDialogCloseActions {
  type: 'closeDialog';
}

export interface MarkdownDialogSetContentActions {
  type: 'setContent';
  payload: {
    markdown?: Array<CmsLegalContent> | undefined | null;
    title: string;
    contentfulISI?: any;
    contentfulTitle?: Maybe<
      Array<ImportantSafetyInformationTemplateIsiHeaderTemplate>
    >;
  };
}

export type MarkdownDialogActions =
  | MarkdownDialogToggleActions
  | MarkdownDialogSetContentActions
  | MarkdownDialogOpenActions
  | MarkdownDialogCloseActions;

interface MarkdownDialogState {
  markdown?: Array<CmsLegalContent> | undefined | null;
  title: string;
  contentfulISI?: any;
  contentfulTitle?: Maybe<
    Array<ImportantSafetyInformationTemplateIsiHeaderTemplate>
  >;
  isDialogOpen: boolean;
}

type Dispatch = (action: MarkdownDialogActions) => void;

const MarkdownDialogStateContext = React.createContext<MarkdownDialogState>({
  isDialogOpen: false,
  markdown: undefined,
  title: '',
  contentfulISI: undefined,
  contentfulTitle: undefined,
});
const MarkdownDialogDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function markdownDialogReducer(
  state: MarkdownDialogState,
  action: MarkdownDialogActions
) {
  switch (action.type) {
    case 'setContent': {
      return {
        markdown: action.payload.markdown,
        title: action.payload.title,
        contentfulISI: action.payload.contentfulISI,
        contentfulTitle: action.payload.contentfulTitle,
        isDialogOpen: state.isDialogOpen,
      };
    }
    case 'toggle': {
      return {
        ...state,
        isDialogOpen: !state.isDialogOpen,
      };
    }
    case 'closeDialog': {
      return {
        ...state,
        isDialogOpen: false,
      };
    }
    case 'openDialog': {
      return {
        ...state,
        isDialogOpen: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const MarkdownDialogProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(markdownDialogReducer, {
    isDialogOpen: false,
    markdown: undefined,
    title: '',
    contentfulISI: undefined,
    contentfulTitle: undefined,
  });
  return (
    <MarkdownDialogStateContext.Provider value={state}>
      <MarkdownDialogDispatchContext.Provider value={dispatch}>
        {children}
      </MarkdownDialogDispatchContext.Provider>
    </MarkdownDialogStateContext.Provider>
  );
};

function useMarkdownDialogState() {
  const context = React.useContext(MarkdownDialogStateContext);
  if (context === undefined) {
    throw new Error(
      'useMarkdownDialogState must be used within a markdownDialogProvider'
    );
  }
  return context;
}

function useMarkdownDialogDispatch() {
  const context = React.useContext(MarkdownDialogDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useMarkdownDialogDispatch must be used within a markdownDialogProvider'
    );
  }
  return context;
}

export {
  MarkdownDialogProvider,
  useMarkdownDialogDispatch,
  useMarkdownDialogState,
};
