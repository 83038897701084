import { getColorToken } from '@allergan-data-labs/alle-elements-core';
import { Flex } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';

export const showAnimation = (x: string, y: string) => keyframes`
  from { transform: translate(${x}, ${y}); }
  to { transform: translate(${x}, 0); }
`;

export const getSnippetText = ({
  isDefaultOptedOut,
  showLongerSnippet,
}: {
  isDefaultOptedOut: boolean;
  showLongerSnippet: boolean;
}) => {
  switch (true) {
    case isDefaultOptedOut && showLongerSnippet:
      return `AbbVie may use and disclose online usage data about you collected
        automatically through online tracking technologies such as cookies,
        pixels, and web beacons (collectively, “cookies”). We use this data for
        multiple purposes, including for online targeted advertising...`;
    case isDefaultOptedOut:
      return `AbbVie may use and disclose online usage data about you collected automatically...`;
    default:
      return `AbbVie may use and disclose online usage data about you collected
        automatically through online tracking technologies such as...`;
  }
};

export const Container = styled(Flex).attrs({
  direction: 'column',
})<{ $isDefaultOptedOut: boolean }>`
  background-color: ${(props) =>
    props.$isDefaultOptedOut
      ? getColorToken('Section bg/Neutral/Base', 'dark')
      : getColorToken('Section bg/Neutral/Inverted', 'dark')};
  color: ${(props) =>
    props.$isDefaultOptedOut
      ? getColorToken('Section bg/Neutral/Inverted', 'dark')
      : getColorToken('Section bg/Neutral/Base', 'dark')};
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  transition: height 0.5s ease-out;
  animation: ${showAnimation('0', '200px')} linear 0.5s;
`;
