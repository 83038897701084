import React from 'react';
import {
  FooterCodeID,
  useCMSFooter,
} from '@allergan-data-labs/contentful-graphql';
import { Copyright2022 } from '@allergan-data-labs/component-library/src/constants/copyright';
import { cmsConsumerFooterToProps } from '@allergan-data-labs/component-library/src/components/footers/consumerFooterHelpers';
import { ConsumerFooter } from './consumerFooter';

const DefaultConsumerFooter: React.FunctionComponent = () => {
  const [footerData, footerLoading] = useCMSFooter({
    codeId: FooterCodeID.defaultConsumerFooter,
  });

  if (!footerLoading) {
    return <ConsumerFooter {...cmsConsumerFooterToProps(footerData)} />;
  } else {
    return <ConsumerFooter copyright={<Copyright2022 />} />;
  }
};

export { DefaultConsumerFooter };
