// Material
import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { above } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';
import { alleColors } from '../colorPalette';
// Core
import { colors } from '../core/colors';

const StyledAppBar = styled(({ variant, ...forwardProps }) => (
  <AppBar {...forwardProps} />
))`
  &.MuiAppBar-root {
    @media print {
      display: none;
    }

    background-color: ${({ variant, theme }) => {
      return theme.styledAppBar[variant].backgroundColor;
    }};

    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    padding: 32px 24px;

    ${above.desktop`
    `}
  }
`;

interface HeaderContainerProps {
  variant:
    | 'dark'
    | 'light'
    | 'nude'
    | 'transparent'
    | 'transparentBlackText'
    | 'demoWarning'
    | 'newDark'
    | 'newNude';
  children?: React.ReactNode;
  className?: string;
  id?: string;
  ['data-testid']?: string;
}

const themes = {
  styledAppBar: {
    light: {
      backgroundColor: colors.white,
    },
    dark: {
      backgroundColor: colors.black,
    },
    newDark: { backgroundColor: alleColors.ravenBlack },
    newNude: { backgroundColor: alleColors.nude[100] },
    nude: {
      backgroundColor: colors.bone,
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    transparentBlackText: {
      backgroundColor: 'transparent',
    },
    demoWarning: {
      backgroundColor: colors.demoWarning,
    },
  },
};

const HeaderContainer: React.FunctionComponent<HeaderContainerProps> = ({
  variant,
  children,
  className,
  id,
  ...props
}) => {
  return (
    <ThemeProvider theme={themes as any}>
      <StyledAppBar
        id={id}
        position="static"
        variant={variant}
        className={className}
        data-testid={props['data-testid']}
      >
        {children}
      </StyledAppBar>
    </ThemeProvider>
  );
};

export { HeaderContainer };
