import React from 'react';

import {
  createInstance,
  enums,
  OptimizelyProvider,
  setLogLevel,
} from '@optimizely/react-sdk';

import optimizelyDataFile from './optimizelyDataFile.json';

import { logger } from '../datadog/dataDog';
import { UserInfo } from '@optimizely/react-sdk/dist/utils';

interface OptimizelyConfigTable {
  [local: string]: string;
  dev: string;
  stage: string;
  demo: string;
  production: string;
}

const optimizelySdkKeyTable: OptimizelyConfigTable = {
  local: 'GfDg6PhZn8DGUwZcFeW2CJ', // Use stage key in order to fetch the updated file
  dev: 'QPiJfSdgVdQwUBRcja4geR',
  stage: 'GfDg6PhZn8DGUwZcFeW2CJ',
  demo: 'Sd1RoVXqF2nvQ5oVBKqQjc',
  production: 'YZBQLkVJgKgp4fzis3B1BM',
};

const environment = process.env.REACT_APP_OPTIMIZELY_ENV || 'stage';

if (
  !process.env.REACT_APP_OPTIMIZELY_LOGGING ||
  process.env.REACT_APP_OPTIMIZELY_LOGGING === 'false'
) {
  setLogLevel(enums.LOG_LEVEL.ERROR);
}

const getCustomKey = () => {
  if (environment === 'custom') {
    const customKey = process.env.REACT_APP_OPTIMIZELY_KEY;
    if (!customKey || typeof customKey !== 'string') {
      throw new Error('Optimizely: No custom template KEY provided');
    }

    return process.env.REACT_APP_OPTIMIZELY_KEY as string;
  }

  return optimizelySdkKeyTable[environment];
};

const getUrlTemplate = () => {
  if (environment === 'custom') {
    const customURL = process.env.REACT_APP_OPTIMIZELY_TEMPLATE;
    if (!customURL || typeof customURL !== 'string') {
      throw new Error('Optimizely: No custom template URL provided');
    }

    return process.env.REACT_APP_OPTIMIZELY_TEMPLATE as string;
  }

  return environment === 'local'
    ? 'https://stage.alle.com/optimizely/datafiles/%s.json'
    : `${window.location.origin}/optimizely/datafiles/%s.json`;
};

const optimizely = createInstance({
  sdkKey: getCustomKey(),
  datafileOptions: {
    // String substitution usage preferred , see: https://docs.developers.optimizely.com/full-stack/docs/initialize-sdk-javascript#section-notes
    urlTemplate: getUrlTemplate(),
  },
  // fallback datafile:
  // When initializing with both the SDK key and datafile, the SDK will use the given datafile to start,
  // then download the latest version of the datafile in the background.
  // source: https://docs.developers.optimizely.com/full-stack/docs/initialize-sdk-javascript#section-examples
  datafile: optimizelyDataFile,
});

optimizely
  .onReady()
  .then((result: any) => {
    if (!result.success) {
      logger.warn(`optimizelyInstance#onReady ${result.reason}`);
    }

    return result;
  })
  .catch((error) => {
    console.error(`optimizelyInstance#catch ${JSON.stringify(error)}`);
  });

export interface OptimizelyUser<
  T extends UserInfo['attributes'] = Record<string, any>
> extends UserInfo {
  id: string;
  attributes: T;
}

interface AlleFeatureProviderProps {
  children: React.ReactNode;
  isServerSide?: boolean;
  useResolveOptimizelyUser: () =>
    | OptimizelyUser<any>
    | Promise<OptimizelyUser<any>>;
}

const AlleFeatureProvider = ({
  children,
  isServerSide,
  useResolveOptimizelyUser,
}: AlleFeatureProviderProps) => {
  const user = useResolveOptimizelyUser();

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={user}
      isServerSide={isServerSide}
    >
      {children}
    </OptimizelyProvider>
  );
};

export { AlleFeatureProvider };
