import { Gender } from '@allergan-data-labs/alle-graphql-types';

const startOfPhoneNumberRegex = /^\(?[2-9][0-9]*\)? ?[0-9]*-?[0-9]*$/;

const isStartOfValidPhoneNumber = (value: string): boolean => {
  return startOfPhoneNumberRegex.test(value);
};

const stripPhoneNumberValue = (value: string): string => {
  return value
    .split('')
    .filter((c) => !['(', ')', '-', ' '].includes(c))
    .join('');
};

const snakeCase = (value: string): string => {
  return value.replace(/\s/g, '_');
};

const injectRouteParams = (
  route: string,
  params: { [key: string]: string },
  query?: { [key: string]: string }
): string => {
  const keys = Object.keys(params);

  for (const key of keys) {
    route = route.replace(':' + key, encodeURIComponent(params[key]));
  }

  if (query) {
    for (const key in query) {
      if (!route.includes('?')) {
        route = `${route}?${key}=${query[key]}`;
      } else {
        route = `${route}&${key}=${query[key]}`;
      }
    }
  }
  return route;
};

const formatGender = (gender?: Gender | null) => {
  switch (gender) {
    case Gender.Female:
      return 'Female';
    case Gender.Male:
      return 'Male';
    case Gender.Other:
      return 'Other';
    case Gender.PreferNotToSay:
      return 'Prefer not to say';
    case Gender.Unspecified:
    default:
      return 'Unspecified';
  }
};

interface FormatInitialsOptions {
  firstName?: string | null;
  lastName?: string | null;
}

const formatInitials = (options?: FormatInitialsOptions | null): string => {
  if (options) {
    const firstInitial =
      options.firstName && options.firstName.length ? options.firstName[0] : '';
    const lastInitial =
      options.lastName && options.lastName.length ? options.lastName[0] : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  }
  return '';
};

const isValidVersion = (version: string): boolean => {
  return /^\d+\.\d+\.\d+$/.test(version);
};

const getMajorVersion = (version: string): number => {
  if (!isValidVersion(version)) {
    throw new Error(`Invalid version specified: ${version}`);
  }
  return parseInt(version.split('.')[0]);
};

const maskBankAccountNumber = (number: string) => {
  return number
    .split('')
    .map((digit, index) => {
      if (index < number.length - 4) {
        return 'X';
      }

      return digit;
    })
    .join('');
};

const redactSensitiveDataFromURL = (url: string) => {
  return url.replace(/at=[^&]*/, 'at=REDACTED');
};

const formatImage = (src: string, width: number, height: number) => {
  const tempSrc = src.split('/upload/');
  return `${tempSrc[0]}/upload/w_${width},h_${height}/${tempSrc[1]}`;
};

export {
  isStartOfValidPhoneNumber,
  stripPhoneNumberValue,
  snakeCase,
  injectRouteParams,
  formatGender,
  formatInitials,
  isValidVersion,
  getMajorVersion,
  maskBankAccountNumber,
  redactSensitiveDataFromURL,
  formatImage,
};
