import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { lightTheme, darkTheme, boneTheme } from '../themes/alleButton';
import { Link, LinkProps } from './link';
import { above } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';

export interface ButtonLinkProps extends LinkProps {
  buttonType?: 'outlined' | 'contained';
  variant?: 'dark' | 'light' | 'bone';
}

const StyledLink = styled(({ buttonType, variant, ...forwardProps }) => (
  <Link {...forwardProps} />
))`
  box-sizing: border-box;
  width: 100%;
  border: ${(props) => props.theme[props.buttonType].borderColor};
  background-color: ${(props) => props.theme[props.buttonType].backgroundColor};
  border-radius: 4px;
  padding: 8px 16px;
  color: ${(props) => props.theme[props.buttonType].color};
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    color: ${(props) => props.theme[props.buttonType].color};
  }

  &:hover {
    border: ${(props) => props.theme[props.buttonType].hoverBorderColor};
    background-color: ${(props) =>
      props.theme[props.buttonType].hoverBackgroundColor};
    p {
      color: ${(props) => props.theme[props.buttonType].hoverColor};
    }
  }

  ${above.tablet`
    max-width: 176px;
  `}

  ${above.desktop`
    max-width: 224px;
  `}
`;

const ButtonLink: React.FunctionComponent<ButtonLinkProps> = ({
  buttonType = 'outlined',
  variant = 'light',
  to,
  children,
  ...forwardLinkProps
}) => {
  const theme =
    variant === 'dark'
      ? darkTheme
      : variant === 'light'
      ? lightTheme
      : variant === 'bone'
      ? boneTheme
      : lightTheme;

  return (
    <ThemeProvider theme={theme as any}>
      <StyledLink buttonType={buttonType} to={to} {...forwardLinkProps}>
        {children}
      </StyledLink>
    </ThemeProvider>
  );
};

export { ButtonLink };
