import React from 'react';
import styled from 'styled-components';
import Slide from '@material-ui/core/Slide';

import { alleColors } from '../colorPalette';
import { HeaderContainer } from './headerContainer';
import { useScrollThreshold, useScrollTrigger } from '../hooks';

import { above } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';

const FixedContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 100;
`;

const StyledHeaderContainer = styled(HeaderContainer)`
  && {
    align-items: center;
    padding: 16px 24px;
  }

  &.card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    max-width: calc(100% - 16px);
    margin: 8px auto;
    padding: 16px;
    background-color: ${alleColors.white};

    ${above.tablet`
      max-width: calc(100% - 24px);
    `};
    ${above.desktop`
      max-width: calc(100% - 32px);
    `};
  }
`;

interface SliderHeaderContainerProps {
  variant: 'light' | 'transparent' | 'transparentBlackText';
  children?: React.ReactNode;
  ['data-testid']?: string;
}

const SlideHeaderContainer: React.FunctionComponent<
  SliderHeaderContainerProps
> = ({ children, variant, ...props }) => {
  const { passedThreshold } = useScrollThreshold({ threshold: 1 });
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger} timeout={350}>
      <FixedContainer data-testid={props['data-testid']}>
        <StyledHeaderContainer
          className={passedThreshold ? 'card' : undefined}
          variant={variant}
        >
          {children}
        </StyledHeaderContainer>
      </FixedContainer>
    </Slide>
  );
};

export { SlideHeaderContainer };
