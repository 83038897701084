import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import { useLazyQuery } from '@apollo/react-hooks';
import { client } from '@allergan-data-labs/shared-sdk/src/client';

// component-library
import { usePrevious } from '@allergan-data-labs/component-library/src/hooks';
import { logger } from '@allergan-data-labs/component-library/src/datadog/dataDog';

// consumer-component-library
import { useSegment } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';
import {
  LoginSucceeded,
  LogoutSucceeded,
} from '@allergan-data-labs/consumer-component-library/src/segment-tracking-plan/generated/index';

// local components
import { authMachine, AuthState } from './authMachine';
import { AuthContext, CurrentUser } from './AuthContext';
import { Routes } from '../constants/routes';
import { UTM_TRACKING_KEY } from '../constants/localStorage';
import { oktaTokenIds } from '../application.config';
import {
  oktaAuth,
  signOut,
  closeSession,
  subscribeToErrorEvents,
  subscribeToLogoutEvents,
  unsubscribeToErrorEvents,
  unsubscribeToLogoutEvents,
  validateToken,
  validateTokenSync,
} from './oktaClient';
import {
  ConsumerProfileQuery,
  QUERY_PROFILE,
} from '../utils/hooks/useLazyConsumerProfile';
import { isAuthenticationRoute } from './authFlow/authenticationStore';

declare global {
  interface Window {
    appboy: {
      wipeData: () => void;
    };
  }
}

const logoutProfileSucceededSegment: LogoutSucceeded = {
  event: {
    action_source: 'consumer web',
    activator: `User logged out of app`,
    explainer: `Tracking user logouts`,
    invoked_by: 'consumer',
    user_type: 'consumer',
  },
  logout_type: 'consumer web',
};

const logoutSucceededSegment: LogoutSucceeded = {
  event: {
    action_source: 'consumer web',
    activator: `User logged out of app`,
    explainer: `Tracking user logouts`,
    invoked_by: 'authentication expired',
    user_type: 'consumer',
  },
  logout_type: 'consumer web',
};

const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();
  const location = useLocation<{ isInlineAuth: boolean }>();
  const { syncSegmentIdentity, trackConsumer } = useSegment();
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [isInlineAuth, setIsInlineAuth] = useState<boolean>(false);
  const [consumerProfileLoading, setConsumerProfileLoading] =
    useState<boolean>(false);
  const [trackLoginSucceeded, setTrackLoginSucceeded] =
    useState<LoginSucceeded | null>(null);
  const [getConsumerProfile, { loading, error: consumerProfileError }] =
    useLazyQuery<ConsumerProfileQuery>(QUERY_PROFILE, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const profile = data.viewer?.profile;
        setCurrentUser(profile || null);
        setConsumerProfileLoading(false);
        if (profile && trackLoginSucceeded) {
          trackConsumer()?.loginSucceeded({
            ...trackLoginSucceeded,
            consumer: {
              ...trackLoginSucceeded?.consumer,
              alle_id: profile?.id || undefined,
            },
          });
          setTrackLoginSucceeded(null);
        }
      },
      onError: () => {
        setConsumerProfileLoading(false);
      },
    });

  useEffect(() => {
    loading && setConsumerProfileLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (
      !isInlineAuth &&
      currentUser?.id &&
      !consumerProfileLoading &&
      !isAuthenticationRoute(location.pathname) &&
      (!currentUser?.privacyTermsAcceptedAt || !currentUser?.hipaaAcceptedAt)
    ) {
      history.replace(Routes.login, location.state);
    }
  }, [
    consumerProfileLoading,
    currentUser,
    history,
    location.pathname,
    location.state,
    isInlineAuth,
  ]);

  // Note: newer versions of okta include this helper method https://github.com/okta/okta-auth-js#tokenisloginredirect
  const isLoginRedirect = useCallback(
    () => matchPath(location.pathname, Routes.authCallback)?.isExact,
    [location.pathname]
  );

  const [authState, sendAuthEvent] = useMachine(
    authMachine.withConfig({
      services: {
        verifyOktaLoggedIn: async () => {
          try {
            // First, check if tokens are present in local storage
            await validateToken();
          } catch (error) {
            // Next, check if currently logging in via redirect URL

            const isExactPath = isLoginRedirect();
            if (!isExactPath) {
              // We should only attempt to login via redirect at Routes.authCallback
              throw error;
            }

            // Parse tokens and state from URL and login if possible
            try {
              const {
                state,
                tokens: { idToken, accessToken },
              } = await oktaAuth.token.parseFromUrl();

              // Attempt to parse state to see if we should redirect users back to where they were originally going
              let parsedState = undefined;
              try {
                parsedState = state
                  ? JSON.parse(decodeURIComponent(state))
                  : undefined;
              } catch (err) {
                // No need to rethrow, state will not be valid JSON unless we are redirecting
                // somewhere besides default (account dashboard)
              }
              if (idToken && accessToken) {
                validateTokenSync({ idToken, accessToken });
                oktaAuth.tokenManager.add(oktaTokenIds.idToken, idToken);
                oktaAuth.tokenManager.add(
                  oktaTokenIds.accessToken,
                  accessToken
                );
                history.replace(parsedState || Routes.accountDashboard);
              } else {
                throw new Error(
                  'idToken && accessToken not present but token.parseFromUrl did not throw'
                );
              }
            } catch (err) {
              console.error('Failed to parse token from URL', err);
              // This can happen if a provider or admin visits /login with a valid okta session present
              // They will automatically get redirected to /implicit/callback with an access token,
              // validateTokenSync will throw due to invalid group, so we send them to /logout which will
              // destroy the current okta session and send them back to /login
              history.replace(Routes.logout);
              throw err;
            }
          }
        },
        clearCache: client.clearStore,
        logout: () => {
          return signOut({ postLogoutRedirectUri: Routes.login });
        },
        logoutProfile: () => {
          return signOut({ postLogoutRedirectUri: Routes.login });
        },
        logoutNoRedirect: closeSession,
      },
      actions: {
        clearSegment: () => {
          logger.info('segment identity removed', {
            SID: window.analytics?.user?.().anonymousId?.(),
          });
          window.analytics?.reset();
          localStorage.removeItem('alle_anonymous_id'); // this will be used as segment anonId if it's present when setting
        },
        clearBraze: () => {
          logger.info('braze identity removed', {
            SID: window.analytics?.user?.().anonymousId?.(),
          });
          window.appboy?.wipeData();
        },
        clearUTMValues: () => {
          localStorage.removeItem(UTM_TRACKING_KEY);
        },
        redirectCustomLocation: (context) => {
          if (context.redirectURI) {
            const redirectURI = decodeURIComponent(context.redirectURI);
            window.location.href = redirectURI;
          }
        },
        setRedirectURI: assign({
          redirectURI: (_, event) => event.redirectURI,
        }),
        storeOktaToken: (_, event) => {
          const { idToken, accessToken } = event;
          oktaAuth.tokenManager.add(oktaTokenIds.idToken, idToken);
          oktaAuth.tokenManager.add(oktaTokenIds.accessToken, accessToken);
        },
        syncSegmentIdentityTraits: () => {
          syncSegmentIdentity();
        },
        cleanupLocalStorage: () => {},
        logoutStartedTrack: () => {
          logger.info('auth provider logout started', {
            SID: window.analytics?.user?.().anonymousId?.(),
          });
        },
        logoutCompletedTrack: () => {
          logger.info('auth provider logout completed', {
            SID: window.analytics?.user?.().anonymousId?.(),
          });
          trackConsumer()?.logoutSucceeded(logoutSucceededSegment);
        },
        logoutCompletedProfileTrack: () => {
          logger.info('profile logout completed', {
            SID: window.analytics?.user?.().anonymousId?.(),
          });
          trackConsumer()?.logoutSucceeded(logoutProfileSucceededSegment);
        },
        populateCurrentUser: () => {
          getConsumerProfile();
        },
        updateTrackLoginSucceeded: (context, event) => {
          // need to wait for consumer profile query to track login succeeded so alle_id can be attached to the event
          // set tracking info in state and useEffect that runs when profile is updated will send tracking event
          const { loginSucceededTracking } = event;
          loginSucceededTracking &&
            setTrackLoginSucceeded(loginSucceededTracking);
        },
      },
    })
  );

  React.useEffect(() => {
    subscribeToErrorEvents(() => {
      sendAuthEvent('LOGOUT');
    });

    subscribeToLogoutEvents(() => {
      sendAuthEvent('LOGOUT');
    });

    return () => {
      unsubscribeToErrorEvents();
      unsubscribeToLogoutEvents();
    };
  }, [sendAuthEvent]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        sendAuthEvent,
        currentUser,
        consumerProfileLoading,
        consumerProfileError,
        setIsInlineAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

const useOnAuthResult = (
  callback: (result: 'isLoggedIn' | 'isNotLoggedIn') => void
) => {
  const { authState } = useAuth();

  const authStateValue = authState.value;
  const prevAuthStateValue = usePrevious(authStateValue);

  // The goal is to call the callback function only once for component using this hook.
  // The callback will be called in one of two situations:
  //   1. Component mounts when auth state is already determined
  //      - prevAuthStateValue is undefined on first render
  //      - authStateValue is logged in or not
  //   2. Component mounts when auth state is not yet determined
  //      - once determined, prevAuthStateValue shows we are coming from loading state: AuthState.checkOktaLoggedIn
  //      - authStateValue is logged in or not
  React.useEffect(() => {
    if (
      (prevAuthStateValue === AuthState.checkOktaLoggedIn ||
        prevAuthStateValue === undefined) &&
      (authStateValue === AuthState.isLoggedIn ||
        authStateValue === AuthState.isNotLoggedIn)
    ) {
      callback(authStateValue);
    }
    // Unfortunately this component doesn't support providing a new callback, as that would break
    // the functionality of only calling the callback once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStateValue, prevAuthStateValue]);
};

export { AuthProvider, useAuth, useOnAuthResult };
