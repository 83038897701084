/* istanbul ignore file */

import { Routes } from './constants/routes';

export const baseUrl = window.location.origin;

const TWO_HOURS_IN_SECONDS = 7200;

export const customOktaSettings = {
  redirectUri: `${baseUrl}${Routes.authCallback}`,
  // Allow users to authenticate even if their clock is not accurate for up two hours
  // This fixes an issue where some users see "JWT was issued in the future" errors
  maxClockSkew: TWO_HOURS_IN_SECONDS,
};

export const oktaTokenIds = {
  accessToken: 'consumer.accessTokenId',
  idToken: 'consumer.idToken',
};

/** @deprecated We are relying on OneTrust to manage cookies, should use ONETRUST_COOKIE_NAME (max age also handled by OneTrust)  */
export const cookiesSettings = {
  name: process.env.REACT_APP_COOKIES_NAME || 'alleConsent',
  maxAge: +(process.env.REACT_APP_COOKIES_MAX_AGE || 31536000),
};
export const ONETRUST_COOKIE_NAME = 'OptanonAlertBoxClosed'; // max age handled by OneTrust
export const ONETRUST_BANNER_CLOSED_COOKIE_NAME = 'OneTrustClose'; // used to track closing of cookie banner with no user selection (session cookie)

export const __CMS_PREVIEW__ =
  process.env.REACT_APP_CMS_HOST === 'preview.contentful.com';

export const __PROD__ = process.env.NODE_ENV === 'production';
export const __DEV__ = process.env.NODE_ENV === 'development';

export const isAlleDown = process.env.REACT_APP_ALLE_DOWN === 'true';

export const inactivityTimer = {
  session: 3600000, // 1 hours
};

export const optimizelyUuidKey = 'consumer.optimizelyUuid';

export const splitAuthorizationKey =
  process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY ||
  'mvrtp2rghejt8r1r0qv6rria7mb46nqt1p9l';

export const SPLIT_DEBUGGER_ENABLED =
  process.env.REACT_APP_SPLIT_DEBUGGER === 'enabled';

export const contentful = {
  spaceId: 'owuwz5p532r4',
  accessToken: '4pZvsGZHXiYQADs_WK_ydKardzqrQKNnBziXOb-CtUE',
  host: `https://graphql.contentful.com/content/v1/spaces/owuwz5p532r4`,
};

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

interface TentpoleCtas {
  botoxPhase3CTA: string;
  botoxPhase3RegistrationInterstitialCTA: string;
  botoxPhase4CTA: string;
  botoxPhase4RegistrationInterstitialCTA: string;
  giftCards: string;
  giftCardsRegistrationInterstitialCTA: string;
  juvedermDayPhase2CTA: string;
  juvedermDayPhase2RegistrationInterstitialCTA: string;
  mothersDayGiftCardSite: string;
}

type TentpoleEnvTypes = 'production' | 'stage';

export const tentpoleConfig: Record<TentpoleEnvTypes, TentpoleCtas> = {
  production: {
    botoxPhase3CTA: 'https://botoxcosmeticday.alle.com/',
    botoxPhase3RegistrationInterstitialCTA:
      'https://botoxcosmeticday.alle.com/checkout',
    botoxPhase4CTA: 'https://holidaygc.alle.com',
    botoxPhase4RegistrationInterstitialCTA:
      'https://holidaygc.alle.com/checkout',
    giftCards: 'https://giftcards.alle.com',
    giftCardsRegistrationInterstitialCTA: 'https://giftcards.alle.com/checkout',
    juvedermDayPhase2CTA: 'https://juvedermday.alle.com/',
    juvedermDayPhase2RegistrationInterstitialCTA:
      'https://juvedermday.alle.com/checkout',
    mothersDayGiftCardSite: 'https://mothersday.alle.com/',
  },
  stage: {
    botoxPhase3CTA: 'https://botoxcosmeticday.dev-alle.com/',
    botoxPhase3RegistrationInterstitialCTA:
      'https://botoxcosmeticday.dev-alle.com/checkout',
    botoxPhase4CTA: 'https://holidaygc.staging-brilliantconnections.com',
    botoxPhase4RegistrationInterstitialCTA:
      'https://holidaygc.staging-brilliantconnections.com/checkout',
    giftCards: 'https://giftcards.staging-brilliantconnections.com/',
    giftCardsRegistrationInterstitialCTA:
      'https://giftcards.staging-brilliantconnections.com/checkout',
    juvedermDayPhase2CTA: 'https://juvedermday.dev-alle.com/',
    juvedermDayPhase2RegistrationInterstitialCTA:
      'https://juvedermday.dev-alle.com/checkout',
    mothersDayGiftCardSite: 'https://mothersday.dev-alle.com/',
  },
};

const tentpoleEnv = process.env.REACT_APP_BOTOX_ENV;
export const currentTentpoleConfig =
  tentpoleEnv === 'production'
    ? tentpoleConfig.production
    : tentpoleConfig.stage;

export const botoxPhase3Cta = currentTentpoleConfig.botoxPhase3CTA;
export const botoxPhase3RegistrationInterstitialCta =
  currentTentpoleConfig.botoxPhase3RegistrationInterstitialCTA;
export const botoxPhase4Cta = currentTentpoleConfig.botoxPhase4CTA;
export const botoxPhase4RegistrationInterstitialCta =
  currentTentpoleConfig.botoxPhase4RegistrationInterstitialCTA;
export const alleGiftCardsLink = currentTentpoleConfig.giftCards;
export const alleGiftCardsRegistrationInterstitialLink =
  currentTentpoleConfig.giftCardsRegistrationInterstitialCTA;
export const juvedermDayPhase2CTA = currentTentpoleConfig.juvedermDayPhase2CTA;
export const juvedermDayPhase2RegistrationInterstitialCTA =
  currentTentpoleConfig.juvedermDayPhase2RegistrationInterstitialCTA;
export const mothersDayGiftCardSiteLink =
  currentTentpoleConfig.mothersDayGiftCardSite;

export type BotoxDayPhaseId =
  | 'phase_1'
  | 'phase_2'
  | 'phase_3'
  | 'phase_4'
  | 'stand_by_1'
  | 'stand_by_2'
  | 'stand_by_3';

export function assertUnreachable(errorValue: never): never {
  console.error('botox phase', errorValue);
  throw new Error(
    'unknown botox day phase, please check application.config.js'
  );
}

// cache value at application start
const currentBotoxPhase = process.env['REACT_APP_BOTOX_PHASE'];
export const getBotoxPhase = (envVar = currentBotoxPhase): BotoxDayPhaseId => {
  if (envVar === undefined) {
    return 'phase_1';
  }

  switch (envVar) {
    case 'phase_1':
    case 'phase_2':
    case 'phase_3':
    case 'phase_4':
    case 'stand_by_1':
    case 'stand_by_2':
    case 'stand_by_3':
      return envVar;

    default:
      assertUnreachable(envVar as never);
  }
};

export const getLandingPageSubdomainUrl = (slug: string): string => {
  switch (baseUrl) {
    case 'https://alle.com':
      return `https://${slug}.alle.com`;
    case 'https://dev.alle.com':
      return `https://${slug}.dev.alle.com`;
    case 'https://demo.alle.com':
      return `https://${slug}.demo.alle.com`;

    default:
      return `https://${slug}.stage.alle.com`;
  }
};
